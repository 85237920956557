(function () {
	'use strict';
	angular
		.module('app')
		.controller('UserCtrl', [
			'$scope',
			'$rootScope',
			'$http',
			'$location',
			'$routeParams',
			'$timeout',
			'seedcodeCalendar',
			'hash',
			'dataStore',
			'manageUser',
			'daybackIO',
			'utilities',
			'environment',
			'onboarding',
			'urlParameters',
			UserCtrl,
		]);

	function UserCtrl(
		$scope,
		$rootScope,
		$http,
		$location,
		$routeParams,
		$timeout,
		seedcodeCalendar,
		hash,
		dataStore,
		manageUser,
		daybackIO,
		utilities,
		environment,
		onboarding,
		urlParameters
	) {
		var platform = utilities.getDBKPlatform();

		$scope.type =
			platform === 'dbkfmjs' || platform === 'dbkfmwd'
				? 'filemakerclient'
				: $routeParams.type;

		$scope.user = {};

		$scope.changeUserPanel = changeUserPanel;

		$scope.gotoPassword = gotoPassword;

		$scope.user.authenticating = dataStore.getState('authenticating', true);

		mobileGoogleAuthentication();

		function gotoPassword(firstName, lastName, organization, email) {
			if (!firstName) {
				$scope.user.message = 'First name is required';
			} else if (!lastName) {
				$scope.user.message = 'Last name is required';
			} else if (!organization) {
				$scope.user.message = 'Organization is required';
			} else if (!email) {
				$scope.user.message = 'Email name is required';
			} else {
				$scope.user.message = '';
				changeUserPanel('password', true);
			}
		}

		function changeUserPanel(name, change) {
			if (!$scope.user) {
				$scope.user = {};
			}
			if (!$scope.user.panel) {
				$scope.user.panel = {};
			}

			$scope.user.panel[name] = change;
		}

		function randomer(max) {
			return Math.floor(Math.random() * (max - 1 + 1)) + 1;
		}

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.randomSelection = randomer(4);

		$scope.logout = function () {
			daybackIO.signOut(null, null, true);
		};

		$scope.login = function login(username, password, remember, provider) {
			if (userParameterCheck()) {
				// Exit if user url params exist
				return;
			}
			if (provider !== 'google' && (!username || !password)) {
				$scope.user.message = 'Username and Password are both required';
				return;
			}
			$scope.user.loading = true;

			manageUser.accountExists(
				username,
				'password',
				function (authPlatform) {
					var title = 'Account Uses Google Sign-In';
					var message;

					// Account exists but is a different sign in method
					if (authPlatform === 'google.com') {
						message =
							'It appears that this email address is already associated with an account using Google Sign-In. Please click the "Sign In With Google" button instead';
						utilities.showModal(
							title,
							message,
							null,
							null,
							'OK',
							null
						);

						$scope.$evalAsync(function () {
							$scope.user.loading = false;
							$scope.user.message = 'Account uses Google Sign-In';
						});
					}
					// Account exists and sign in method matches
					else {
						daybackIO.signIn(username, password, remember, onAuth);
						//Callback for login attempt
						function onAuth(result) {
							$scope.$evalAsync(function () {
								if (result.message) {
									$scope.user.loading = false;
									$scope.user.message = result.message;
								} else {
									if (
										result.user.signIn.isTemporaryPassword
									) {
										$location.path('change-password');
									} else {
										$location.path('/');
									}
								}
							});
						}
					}
				},
				function () {
					// Account doesn't exist
					$scope.$evalAsync(function () {
						$scope.user.loading = false;
						$scope.user.message = 'Incorrect username or password';
					});
				},
				function (error) {
					$scope.$evalAsync(function () {
						$scope.user.loading = false;
					});
				}
			);
		};

		function mobileGoogleAuthentication() {
			if ($scope.user.authenticating) {
				dataStore.saveState('authenticating', '', true);
				firebase
					.auth()
					.getRedirectResult()
					.then(function (result) {
						if (result.credential) {
							var path = $location.path();
							// This gives you a Google Access Token. You can use it to access the Google API.
							var token = result.credential.accessToken;
							var user = result.user;
							var profile = result.additionalUserInfo.profile;

							var userID = user.uid;
							var firstName = profile.given_name;
							var lastName = profile.family_name;
							var userName = profile.email;

							if (path === '/sign-in') {
								manageUser.accountExists(
									userName,
									'google.com',
									function (authPlatform) {
										if (authPlatform === 'google.com') {
											// Exists as google sign in account
											$scope.$evalAsync(function () {
												$location.path('/');
											});
										} else {
											// Exists as a regular account
											var title =
												'Account Uses Password Sign-In';
											var message =
												'It appears that this email address is already associated with an email and password account. Please sign in with your existing email and password rather than using Google';

											utilities.showModal(
												title,
												message,
												null,
												null,
												'OK',
												null
											);

											$scope.$evalAsync(function () {
												$scope.user.authenticating = false;
												$scope.user.message =
													'Account uses email and password';
											});
										}
									},
									function () {
										var title = "Account Doesn't Exist";
										var message =
											'It appears that this email is not associated with an account. You will need to start a free trial first to start using DayBack';

										utilities.showModal(
											title,
											message,
											'Cancel',
											null,
											'Go To Sign-Up',
											$scope.gotoSignUp
										);
										$scope.$evalAsync(function () {
											$scope.user.authenticating = false;
											$scope.user.message =
												"User doesn't exist. Please start a free trial instead.";
										});
									},
									function (error) {
										$scope.$evalAsync(function () {
											$scope.user.authenticating = false;
										});
									}
								);

								// daybackIO.userExists(userID, function(user) {
								// 	if (user) {
								// 		$scope.$evalAsync(function() {
								// 			$location.path('/');
								// 		});
								// 	}
								// 	else {
								// 		$scope.$evalAsync(function() {
								// 			$scope.user.authenticating = false;
								// 			$scope.user.message = "User doesn't exist. Please start a free trial instead.";
								// 		});
								// 	}
								// });
							} else if (path === '/sign-up') {
								manageUser.accountExists(
									userName,
									'google.com',
									function (authPlatform) {
										var title = 'Account Already Exists';
										var message;
										if (authPlatform === 'google.com') {
											// Exists as google sign in account
											message =
												'It appears that this email address is already associated with an account using Google Sign-In. Please click the "Sign In With Google" button on the sign-in page';
										} else {
											// Exists as a regular account
											message =
												'It appears that this email address is already associated with an email and password account. Please sign in with your existing email and password rather than using Google';
										}

										utilities.showModal(
											title,
											message,
											'Cancel',
											null,
											'Go To Sign-In',
											$scope.gotoSignIn
										);
									},
									function () {
										$scope.$evalAsync(function () {
											$scope.user.authenticating = false;
										});
										signup(
											userName,
											null,
											firstName,
											lastName,
											'', // organization Todo: get this from google profile
											$scope.type,
											true,
											'google'
										);
									},
									function () {
										$scope.$evalAsync(function () {
											$scope.user.authenticating = false;
										});
									}
								);
							}
						}
						// The signed-in user info.
					})
					.catch(function (error) {
						$scope.$evalAsync(function () {
							$scope.user.authenticating = false;
							$scope.user.message = error.message;
						});
					});
			}
		}

		$scope.signInGoogle = function () {
			if (userParameterCheck()) {
				// Exit if user url params exist
				return;
			}

			$scope.user.authenticating = true;
			manageUser.signInGoogle(
				function (authPlatform) {
					var title = 'Account Exists With Another Sign-In Method';
					var message;

					if (authPlatform === 'google.com') {
						$scope.$evalAsync(function () {
							$scope.user.authenticating = false;
							$location.path('/');
						});
					} else {
						// Exists as a regular account
						message =
							'It appears that this email address is associated with an email and password account. Please sign in with your existing email and password rather than using Google';

						utilities.showModal(
							title,
							message,
							null,
							null,
							'OK',
							null
						);

						$scope.$evalAsync(function () {
							$scope.user.authenticating = false;
						});
					}
				},
				function () {
					var title = "Account Doesn't Exist";
					var message =
						'It appears that this email is not associated with an account. You will need to start a free trial first to start using DayBack';

					utilities.showModal(
						title,
						message,
						'Cancel',
						null,
						'Go To Sign-Up',
						$scope.gotoSignUp
					);

					$scope.$evalAsync(function () {
						$scope.user.authenticating = false;
						$scope.user.message =
							"User doesn't exist. Please start a free trial instead.";
					});
				},
				function () {
					$scope.$evalAsync(function () {
						$scope.user.authenticating = false;
					});
				},
				false
			);
		};

		$scope.signUpGoogle = function () {
			if (userParameterCheck(true)) {
				// Exit if user url params exist
				return;
			}

			$scope.user.authenticating = true;

			manageUser.signInGoogle(
				function (authPlatform) {
					var title = 'Account Already Exists';
					var message;
					if (authPlatform === 'google.com') {
						// Exists as google sign in account
						message =
							'It appears that this email address is already associated with an account using Google Sign-In. Please click the "Sign In With Google" button on the sign-in page';
					} else {
						// Exists as a regular account
						message =
							'It appears that this email address is already associated with an email and password account. Please sign in with your existing email and password rather than using Google';
					}

					utilities.showModal(
						title,
						message,
						'Cancel',
						null,
						'Go To Sign-In',
						$scope.gotoSignIn
					);

					$scope.$evalAsync(function () {
						$scope.user.authenticating = false;
						$scope.user.message =
							'User already exists. Sign-in instead.';
					});
				},
				function (userName, firstName, lastName) {
					signup(
						userName,
						null,
						firstName,
						lastName,
						'', // Organization Todo: get this from google auth payload
						$scope.type,
						true,
						'google'
					);
				},
				function () {
					$scope.$evalAsync(function () {
						$scope.user.authenticating = false;
					});
				},
				true
			);
		};

		$scope.signUp = function (
			firstName,
			lastName,
			organization,
			userName,
			password,
			passwordConfirm,
			remember
		) {
			var signUpType = $scope.type;

			if (userParameterCheck(true)) {
				// Exit if user url params exist
				return;
			}

			if (!firstName) {
				$scope.user.message = 'First name is required';
			} else if (!lastName) {
				$scope.user.message = 'Last name is required';
			} else if (!organization) {
				$scope.user.message = 'Organization is required';
			} else if (!userName) {
				$scope.user.message = 'Email is required';
			} else if (!password) {
				$scope.user.message = 'Password is required';
			} else if (password.length < 6) {
				$scope.user.message =
					'Password must be at least 6 characters long';
			} else if (password !== passwordConfirm) {
				$scope.user.message = "Password confirmation doesn't match";
			} else {
				$scope.user.loading = true;
				manageUser.accountExists(
					userName,
					'password',
					function (authPlatform) {
						$scope.$evalAsync(function () {
							$scope.user.loading = false;
							$scope.user.message =
								'User already exists. Sign-in instead.';
						});
						var title = 'Account Already Exists';
						var message;
						if (authPlatform === 'google.com') {
							// Exists as google sign in account
							message =
								'It appears that this email address is already associated with an account using Google Sign-In. Please click the "Sign In With Google" button on the sign-in page';
						} else {
							// Exists as a regular account
							message =
								'It appears that this email address is already associated with an account. Please sign in with your existing email and password on the sign-in page';
						}

						utilities.showModal(
							title,
							message,
							'Cancel',
							null,
							'Go To Sign-In',
							$scope.gotoSignIn
						);
					},
					function () {
						$scope.user.loading = false;
						signup(
							userName,
							password,
							firstName,
							lastName,
							organization,
							signUpType,
							remember,
							'email'
						);
					},
					function (error) {
						$scope.$evalAsync(function () {
							$scope.user.loading = false;
						});
					}
				);
			}
		};

		function signup(
			userName,
			password,
			firstName,
			lastName,
			organization,
			signUpType,
			remember,
			provider
		) {
			$scope.user.loading = true;

			daybackIO.reset();
			manageUser.reset();

			manageUser.createDayBackUser(
				userName,
				password,
				firstName,
				lastName,
				organization,
				'signup',
				false,
				signUpType,
				provider,
				remember,
				true,
				login
			);
			// manageUser.createDayBackUser(userName, password, firstName, lastName, groupName, origin, isTemporaryPassword, signUpType, remember, doAuth, callback

			// Send event to google tag manager
			try {
				dataLayer.push({event: 'dayback-sign-up'});
			} catch (error) {
				console.log('error', error);
			}

			function login(result) {
				if (result.message) {
					$scope.$apply(function () {
						$scope.user.loading = false;
						$scope.user.message = result.message;
					});
				} else if (result) {
					var authData = manageUser.getAuth();
					authData.then(function (data) {
						createUserData(data);
					});
				} else {
					$scope.$evalAsync(function () {
						$scope.user.loading = false;
						$scope.user.message =
							'Failed to create your account, please try again.';
					});
				}
			}
			function createUserData(result) {
				var userProperties;
				if (result.message) {
					$scope.$apply(function () {
						$scope.user.loading = false;
						$scope.user.message = result.message;
					});
				} else if (result) {
					userProperties = {
						id: result.uid,
						account: userName,
						name: '',
						firstName: firstName,
						lastName: lastName,
						email: userName,
						groupID: null,
						groupName: organization,
						userType: $scope.type,
					};
					daybackIO.createUserData(
						userProperties,
						false,
						false,
						false,
						signUpCallback
					);
				} else {
					$scope.$evalAsync(function () {
						$scope.user.loading = false;
						$scope.user.message =
							'Failed to create your account, please try again.';
					});
				}
			}
			function signUpCallback(signUpData) {
				var activation = signUpData.activation;
				daybackIO.signIn(userName, password, remember, complete, true);
				//Callback after sign in
				function complete(result) {
					$scope.$evalAsync(function () {
						if (result.message) {
							$scope.user.message = result.message;
						} else {
							// Navigate to the calendar
							$location.path('/');
							// Run any route specific routines
							if (
								$scope.type === 'google' ||
								$scope.type === 'office365'
							) {
								//Display modal for google auth
								onboarding.authorizeAccount($scope.type);
							} else {
								onboarding.welcome($scope.type);
							}
						}
					});
					// Create subscription
					$http({
						method: 'POST',
						url: _CONFIG.DBK_TRIAL_URL + '/create',
						data: {
							firstName: firstName,
							lastName: lastName,
							email: userName,
							organization: organization,
							nextBillingAt: activation.currentPeriodEndsAt,
							groupID: signUpData.user.group.id,
							userID: signUpData.user.id,
						},
					})
						.then(function (response) {
							var subscription = response.data.subscription;
							var settings = seedcodeCalendar.get('settings');
							settings.activation.subscriptionID =
								subscription.id;

							hash.update('subscriptionID', subscription.id);

							//Run something after successful post
						})
						.catch(function (error) {
							console.log('error', error);
							//Run something on error
						});

					// Run autopilot journey and add user to contact list
					$http({
						method: 'POST',
						url: _CONFIG.DBK_JOURNEY_URL,
						data: {
							journey: '0001',
							firstName: firstName,
							lastName: lastName,
							email: userName,
							route: signUpType,
							subscriptionStatus: 'Trialing',
							currentPeriodEndsAt: activation.currentPeriodEndsAt,
							invited: '',
						},
					})
						.then(function (response) {
							//Run something after successful post
						})
						.catch(function (error) {
							console.log('error', error);
							//Run something on error
						});
				}
			}
		}

		$scope.resetPassword = function (email) {
			if (!email) {
				$scope.user.message = 'Email is required';
				return;
			}
			$scope.user.loading = true;
			manageUser.resetPassword(email, callback);
			function callback(result) {
				$scope.$apply(function () {
					$scope.user.loading = false;
					$scope.user.passwordReset = result.success;
					$scope.user.message = result.message;
				});
			}
		};

		$scope.gotoSignIn = function () {
			clearPassword();
			$location.path('/sign-in');
		};

		$scope.gotoSignUp = function () {
			clearPassword();
			$location.path('/sign-up');
		};

		$scope.gotoForgotPassword = function () {
			clearPassword();
			$location.path('/forgot-password');
		};

		function clearPassword() {
			$scope.user.password = null;
			$scope.user.passwordConfirm = null;
		}

		function userParameterCheck(signUp) {
			// signUp parameter is boolean if this is called from a sign up or not
			// Returns true if user parameters exist and this should not be a email password sign in / sign up

			var typeString = signUp ? 'sign up' : 'sign in';
			if (
				(urlParameters.userGroupToken && urlParameters.userEmail) ||
				urlParameters.userToken
			) {
				// Can't use sign in method as their is a user specified in the url
				var title = 'Automatic Sign-In Configured';
				var message =
					'It appears that an account is already configured to sign-in automatically. You cannot ' +
					typeString +
					' with an email address and password while an automatic sign-in is configured. You will automatically be signed-in with the previously configured account.';
				utilities.showModal(
					title,
					message,
					null,
					null,
					'OK',
					function () {
						$location.path('/');
					}
				);
				return true;
			}
		}
	}
})();
